@use 'sass:color';
@import '../../common/styles/variables.module';

.sellerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $simplewhite;
  min-height: 100vh;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  [class~='MuiTypography-root'] {
    color: #1f1f1f;
    font-family: 'Poppins';
  }
}

.terminatedProductWindow {
  background: linear-gradient(180deg, #36b8c1 0%, #004a77 100%);
}

.loaderContainer {
  position: fixed;
  background: color.adjust($lightergray, $alpha: -0.02);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 10000;

  img {
    width: 50vw;
    max-width: 500px;
    animation: pulse 0.666s infinite;
    animation-direction: alternate;
    animation-name: pulse;
  }

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

.inactiveProduct {
  height: 70%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  [class~='MuiButton-root'] {
    background-color: $primaryDarkerColor;
  }

  @media only screen and (max-width: $breakpointMedium) {
    padding: 0px;
    min-height: 100vh;
  }
}

.gridContainer {
  width: 80%;
  @media only screen and (max-width: $breakpointMedium) {
    width: 90%;
  }
}

.sellerHeaderAvatar {
  height: auto;
  width: auto;
  max-height: 124px;
  max-width: 250px;
  background: white;
  margin-top: 40px;
  border-radius: 15px;
  @media only screen and (max-width: $breakpointMedium) {
    height: 150px;
    margin-top: 25px;
    margin-bottom: 0px;
    height: unset;
  }
}

.sellerHeaderContent {
  margin: 0px;
  flex: auto;
  [class~='MuiTypography-root'] {
    color: $primaryDarkerColor !important;
  }

  [class~='MuiTypography-h5'] {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  [class~='MuiTypography-h6'] {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  @media only screen and (max-width: $breakpointMedium) {
    [class~='MuiTypography-h5'] {
      font-size: 1.2rem;
    }
  }

  [class~='MuiPaper-root'] {
    box-shadow: none;
  }

  @media only screen and (min-width: $breakpointMedium) {
    display: flex;
    flex-direction: row;
  }
}

.kioskFooter {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 19px;
  margin-top: 20px;
  [class~='MuiTypography-root'] {
    font-size: 12px;
  }

  img {
    width: 125px;
    height: 25px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin-bottom: 30px;
    width: 100%;
  }
}

.subscriptionRes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 50%;
  [class~='MuiTypography-root'] {
    color: white;
  }
  img {
    width: 350px;
    max-width: 90vw;
    max-height: 320px;
  }

  [class~='MuiTypography-h6'] {
    text-align: center;
    font-weight: 400;
  }
  [class~='MuiTypography-h4'] {
    text-align: center;
    font-weight: 600;
  }

  [class~='MuiButton-root'] {
    background-color: $primaryDarkerColor;
  }
  .companyLogo {
    max-height: 300px;
    width: auto;
    @media only screen and (max-width: $breakpointMedium) {
      max-height: 200px;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 90%;
  }
}

.terminatedProductBox {
  background-color: $primaryLightestColor;
  border-radius: $borderRadius;
  width: 47%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    scale: 0.65;
  }
  .productImage {
    width: 100%;
    height: 100%;
    border-radius: $borderRadius;
    scale: 1;
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 70%;
    max-height: 50%;
    img {
      scale: 0.4;
    }
  }
}

.terminatedProductFooter {
  display: flex;
  justify-content: center;
  color: $simplewhite;
  margin-top: 30px;
  line-height: normal;
  img {
    height: 25px;
    margin-left: 5px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin-bottom: 20px;
  }
}

.modal {
  padding: 50px 30px;
  text-align: center;

  .modalHeader {
    margin-bottom: 50px;
  }
  svg {
    margin-bottom: 30px;
  }
  [class~='MuiButton-root'] {
    margin: 20px;
  }

  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 2vh;
    [class~='MuiTypography-h4'] {
      font-size: 1.5rem;
    }
    .modalHeader {
      margin-bottom: 20px;
    }
    svg {
      margin-bottom: 10px;
    }
    [class~='MuiButton-root'] {
      margin: 10px;
    }
  }
}

.paymentButtonContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.button {
  &:hover {
    filter: brightness(85%);
  }
}

.paymentMethods {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
  padding-top: 15px;
  padding-bottom: 30px;
  width: 80%;
  margin: auto;

  [class~='MuiButton-root'] {
    width: 160px;
    height: 40px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  @media only screen and (max-width: $breakpointMedium) {
    padding: 15px 0;
    width: 95%;
    gap: 10px;
  }

  &.loading {
    background-color: black;
  }

  .formRadio {
    display: flex;
    justify-content: center;
    width: 70%;
    @media only screen and (max-width: $breakpointMedium) {
      width: 100%;
    }
  }
  .subscribeCardForm {
    display: flex;
    justify-content: center;
    width: 70%;
    [class~='MuiFormLabel-root'] {
      font-weight: 500;
      color: #1f1f1f;
      font-family: 'Poppins';
    }
    @media only screen and (max-width: $breakpointMedium) {
      width: 80%;
      text-align: center;
      margin-top: 15px;
      flex-direction: column;
      align-items: center;
    }
  }

  .paymentMethod {
    height: 40px;
    margin: 5px;
    padding: 10px;
    border-radius: $borderRadius;
    border: 0.5px solid rgba(0, 0, 0, 0.3);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 30px);

    @media only screen and (max-width: $breakpointMedium) {
      width: calc(100% - 30px);
    }

    cursor: pointer;
    img {
      height: 50%;
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }
}

.verticalCenterTop {
  margin-top: 50px;
}

.verticalCenterBottom {
  margin-bottom: auto;
}

.containerPayment {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  padding-bottom: 50px;
  @media only screen and (max-width: $breakpointMedium) {
    width: 100vw;
    padding: 0px;
    flex: unset;
    min-width: 100vw;
    padding-bottom: 40px;
  }
}

.previewContainerPayment {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 50vw;
  min-height: 70vh;
  align-items: center;
  padding-bottom: 50px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  @media only screen and (max-width: $breakpointMedium) {
    width: 80vw;
    padding: 0px;
    flex: unset;
    min-width: 100vw;
    padding-bottom: 40px;
  }
}

.containerPaymentGateways {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin-top: 20px;
  @media only screen and (max-width: $breakpointMedium) {
    min-width: 100vw;
    padding: 0px;
    flex: unset;
    position: unset;
    top: unset;
  }
}

.containerWidth {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  overflow-x: hidden;
  @media only screen and (max-width: $breakpointMedium) {
    flex-direction: column;
    overflow-y: unset;
    overflow-x: unset;
  }
}

.paperImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $primaryLightestColor;
  border-radius: 10%;
  margin-right: 40px;

  @media only screen and (min-width: $breakpointMedium) {
    height: 150px;
    min-width: 150px;
  }

  @media only screen and (max-width: $breakpointMedium) {
    height: 100px;
    max-width: 100px;
    margin: auto;
    margin-bottom: 20px;
  }

  .imageShoppingCart {
    height: 90%;
    position: relative;
    bottom: -30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}

.imagesContainer {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.productName {
  [class~='MuiTypography-root'] {
    font-size: 20px;
    font-weight: bold;
  }
  @media only screen and (max-width: $breakpointMedium) {
    font-size: 20px;
    font-weight: 600;
  }
}

.productDescription {
  @media only screen and (max-width: $breakpointMedium) {
    font-size: 14px;
  }
}

.priceContainer {
  box-shadow: 0px 0px 9px rgba(180, 180, 180, 0.25);
  border-radius: 20px;
  padding: 20px;
  margin-top: 30px;
  background: white;
}

.mainPriceGrid {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $lightGray;
}

.zpLogoContainer {
  .zpLogo {
    width: 200px;
    height: auto;
    margin: auto;
  }
  a {
    width: 200px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    .zpLogo {
      width: 150px;
    }
    a {
      width: 150px;
    }
  }
}

.terminatedProduct {
  box-shadow: 0px 1px 10px rgba(176, 176, 176, 0.25);
  border-radius: 20px;
  max-height: 50%;
  margin-top: 40px;
  width: 50%;
  background-color: $simplewhite;
  .imageContainer {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0px 1px 10px rgba(176, 176, 176, 0.25);
    margin: auto;
    margin-top: -50px;
    margin-bottom: -50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $simplewhite;
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 60px;
    width: 90%;
  }
}

.groupPriceContainer {
  flex-direction: column;
  [class~='MuiTypography-h5'] {
    font-size: 18px;
    font-weight: 600;
  }
}

.topContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  @media only screen and (max-width: $breakpointMedium) {
    width: 95%;
  }
}

.dataContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
  [class~='MuiInput-root']:before {
    border-bottom-style: solid;
  }
  [class~='MuiTypography-root'] {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  [class~='MuiInputBase-input'] {
    padding: 8.5px 14px;
  }
  [class~='MuiInputLabel-root'] {
    transform: translate(14px, 9px) scale(1);
  }
  [class~='MuiInputLabel-root'][class~='Mui-focused'] {
    transform: translate(14px, -9px) scale(0.75);
  }
  [class~='MuiInputLabel-root'][class~='Mui-disabled'] {
    transform: translate(14px, -9px) scale(0.75);
  }
  [class~='MuiInputLabel-shrink'] {
    transform: translate(14px, -9px) scale(0.75);
  }
  [class~='MuiAccordion-root'] {
    border-radius: 10px;
    color: unset;
  }
  [class~='MuiAccordion-root']::before {
    height: 0px;
  }
  .info {
    display: flex;
    align-items: center;
    grid-template-columns: 2;
    [class~='MuiTypography-root'] {
      font-weight: 400;
      font-size: 16px;
    }
    [class~='MuiTextField-root'] {
      margin-right: 15px;
      width: calc(50% - 15px);
    }
    @media only screen and (max-width: $breakpointMedium) {
      flex-direction: column;
      width: 100%;
      [class~='MuiTextField-root'] {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

.automatizationContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  [class~='MuiCheckbox-root'] {
    padding: 0;
    margin-top: 4px;
    margin-right: 10px;
  }
}

.automatizationTextContainer {
  display: flex;
  flex-direction: column;
  [class~='MuiTypography-root'] {
    font-size: 14px;
  }
}

.dropdownMenu {
  position: relative;

  .list {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    [class~='MuiListItem-root'] {
      padding: 0px;
      width: 100%;
    }
  }

  .selectButton {
    min-width: 100%;
    border-color: $lightGray;
    color: $lightGray;
  }
}

.formCheckoutContainer {
  width: 80%;
  ::placeholder {
    font-size: 16px;
    font-weight: 500;
  }
}
.formCheckout {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formDateAndCode {
  display: flex;
  flex-direction: row;
}

.cardHolderProvider {
  max-width: 35px;
}

.formBaseContainer {
  height: 30px;
  border-radius: $borderRadius;
  border: 1px solid $lightGray;
  padding: 10px;
  margin: 5px;
}

.formCardNumberContainer {
  @extend .formBaseContainer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formCardNumber {
  @extend .formBaseContainer;
  border: none;
  width: calc(100% - 35px);
}

.mpIdentificationNumber {
  border: none;
  width: 100px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
}

.mpIdentificationNumberField {
  @extend .formBaseContainer;
  border: none !important;
  width: calc(100% - 100px);
}

.hidden {
  display: none;
}

.cardInfoContainer {
  padding: 15px 10px 25px;
  width: calc(100% - 20px);
  height: max-content;
  .row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 40px;
  }
}

.whiteSpinnerContainer {
  [class~='MuiCircularProgress-root'] {
    color: white;
  }
}

.stepper {
  [class~='MuiStepper-root'] {
    width: 100%;
    margin-bottom: 10px;
  }
  [class~='MuiStepLabel-label'] {
    margin-top: 5px;
  }
}

.stepperButtons {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  [class~='MuiButton-root'] {
    margin-left: 7px;
  }
  .button {
    &:hover {
      filter: brightness(85%);
    }
  }
}

.stepperContainer {
  margin-top: 40px;
  width: 25%;
  [class~='MuiStepper-root'] {
    width: 100%;
    margin-bottom: 10px;
  }
  [class~='MuiStepLabel-label'] {
    margin-top: 5px;
  }
  [class~='MuiStepLabel-root'] {
    width: 50px;
    height: 50px;
  }
  [class~='MuiStepIcon-text'] {
    font-family: 'Poppins';
  }
  [class~='MuiSvgIcon-root'] {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 1px solid $primaryBlue;
    color: white;
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 70%;
  }
}

.verticalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 30px;
  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 0;
  }
}

.horizontalContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 50px 100px 30px 300px;
  gap: 150px;
}

.buyerData {
  width: 360px;
  @media only screen and (max-width: $breakpointMedium) {
    width: 85%;
    margin: 0;
  }
}

.productSummary {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
  @media only screen and (max-width: $breakpointMedium) {
    width: 85%;
  }
}

.payButton {
  margin-top: 20px;
  width: 170px;
  background-color: white;
  border-color: white;
  color: white;
  height: 46px;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  [class~='MuiCircularProgress-root'] {
    color: white;
  }
  &:hover {
    background-color: $primaryBlue;
    filter: brightness(85%);
  }
}

.zafepayFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 19px;
  [class~='MuiTypography-root'] {
    font-size: 12px;
    color: #1f1f1f;
  }
  margin-bottom: 20px;
  img {
    width: 125px;
    height: 25px;
    margin-left: 8px;
  }
}

.icon {
  width: 21px;
  height: auto;
}

.paymentPreviewContianer {
  display: flex;
  flex-direction: row;
  gap: 50px;
  @media only screen and (max-width: $breakpointMedium) {
    flex-direction: column;
    gap: 50px;
  }
}

.paymentDetailContainer {
  width: 40%;
  [class~='MuiTypography-h2'] {
    margin-bottom: 10px;
    text-align: center;
    font-weight: 600;
    font-size: 35px;
  }
  [class~='MuiTypography-h6'] {
    margin-bottom: 10px;
  }
  p {
    color: white;
  }
  .table {
    margin: auto;
    margin-top: 30px;
    background: #fafbff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(90% - 70px);
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px 25px;
    gap: 20px;
    .title {
      font-size: 25px;
      font-weight: 600;
      p {
        color: $primaryBlue;
      }
    }
    .divider {
      width: 100%;
      color: #eeeded;
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      [class~='MuiTypography-root'] {
        color: #1f1f1f;
      }
      .description {
        font-size: 14px;
      }
      .data {
        font-size: 20px;
        font-weight: 600;
      }
    }
    @media only screen and (max-width: $breakpointMedium) {
      margin: 0;
      margin-top: 30px;
      width: calc(100% - 50px);
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin: 0 30px;
    width: unset;
  }
}

.automaticPaymentMessage {
  margin: auto;
  text-align: center;
  width: 65%;
  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
  }
}

.warningContainer {
  text-align: center;
  margin-bottom: 30px;
  width: 100%;
  padding: 80px 0;
  [class~='MuiTypography-h5'] {
    font-size: 18px;
    font-weight: 400;
  }
  [class~='MuiTypography-h6'] {
    font-size: 13px;
    font-weight: 400;
    margin-top: 8px;
  }
}

.klapFormContainer {
  position: fixed;
  background: rgb(27 26 30 / 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.formContainer {
  padding: 30px 30px 35px;
  width: 30%;
  height: auto;
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  gap: 15px;
  z-index: 10000;
  [class~='MuiTypography-root'] {
    color: #1f1f1f;
  }
  .row {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .inputField {
    width: 100%;
    height: auto;
  }
  .inputFieldSelect {
    width: 100%;
    height: auto;
    [class~='MuiList-rootroot'] {
      z-index: 1000;
    }
  }
  .subscribeCard {
    margin-top: 30px;
    width: 180px;
    background-color: $primaryBlue;
    color: white;
    height: 35px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    [class~='MuiCircularProgress-root'] {
      color: white;
    }
    &:hover {
      background-color: $primaryBlue;
      filter: brightness(85%);
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 80%;
    padding: 15px 10px 25px;
  }
}

.brandRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
  justify-content: center;
  margin-top: 15px;
  img {
    width: 55px;
  }
}

.termsAndConditionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 58%;
  text-align: center;
  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
    justify-content: center;
  }
}

.termsAndConditionsLink {
  text-decoration: underline;
  font-weight: 600;
  cursor: alias;
}
