@use 'sass:color';
@import '../../common/styles/variables.module';
@import '../../common/styles/common.module.scss';

.loaderContainer {
  position: absolute;
  background: color.adjust($lightergray, $alpha: -0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 0;
  z-index: 10000;
  top: 0;
  left: 0;
  img {
    width: 50vw;
    max-width: 500px;
    animation: pulse 0.666s infinite;
    animation-direction: alternate;
    animation-name: pulse;
  }

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    border-radius: unset;
  }
}

.appContainer {
  background: #4653e3;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: $breakpointMedium) {
    height: min-content;
  }
}

.loginContainer {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 24px;
  width: 400px;
  padding: 35px 70px;
  [class~='MuiTypography-root'] {
    font-size: 15px;
    line-height: normal;
    color: #1f1f1f;
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 80%;
    min-height: 100px;
    padding: 25px 25px 60px;
  }
  .logo {
    height: auto;
    width: auto;
    height: 124px;
    max-width: 250px;
    border-radius: 15px;
    img {
      object-fit: contain;
    }
  }
  .title {
    margin: 40px 0 30px;
    [class~='MuiTypography-root'] {
      font-size: 30px;
    }
  }
  .loginInfoBox {
    padding: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-radius: 16px;
    border: 1px solid var(--secundary-04, #1f1f1f);
    background: #fcfcfc;
    [class~='MuiTypography-root'] {
      font-size: 13px;
      color: #1f1f1f;
    }
    .resendEmailLink {
      cursor: pointer;
    }
  }
  .formContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .inputContainer {
    margin-top: 30px;
    width: 100%;
    #email {
      background-color: red;
    }
    [class~='MuiInputBase-root'] {
      border-radius: 60px;
      height: 42px;
    }
    [class~='MuiOutlinedInput-input']
      [class~='MuiInputBase-input']
      [class~='MuiInputBase-input-MuiOutlinedInput-input'] {
      background-color: red;
      border-radius: none;
    }
  }
  .button {
    margin: auto;
    cursor: pointer;
    width: 160px;
    height: 40px;
    border-radius: 28px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    [class~='MuiTypography-root'] {
      color: white;
      font-weight: 500;
      font-size: 16px;
      line-height: unset;
    }
    &:hover {
      filter: brightness(85%);
    }
  }
  .backLink {
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer;
    [class~='MuiTypography-root'] {
      font-size: 13px;
      color: unset;
    }
  }
}

.warningConataienr {
  display: flex;
  align-items: center;
  flex-direction: column;
  .titleWarning {
    [class~='MuiTypography-root'] {
      font-weight: 600;
      font-size: 22px;
      line-height: unset;
    }
  }
}

$gradient: linear-gradient(
  180deg,
  color.adjust($secondaryColor, $alpha: -0.85) 0%,
  $secondaryColor 80%
);
$gradientMobile: linear-gradient(
  180deg,
  color.adjust($secondaryColor, $alpha: -0.75) 0%,
  $secondaryColor 120%
);
$drawerWidth: 400px;

.drawer {
  [class~='MuiDrawer-paper'] {
    width: $drawerWidth;
    height: calc(100vh - 2 * $borderRadius);
    background: transparent;
    color: $simplewhite;
    border: none;
    padding: $borderRadius;
    margin: $borderRadius;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: unset;
    [class~='MuiListItem-root'] {
      border-radius: $borderRadius;
      border-left: 5px solid transparent;
      margin-bottom: 3px;
      padding: 12px 4px;

      [class~='MuiTypography-root'] {
        font-size: 20px;
        line-height: 24px;
        color: white;
      }
      [class~='MuiListItemIcon-root'] {
        display: flex;
        justify-content: center;
        svg {
          color: white;
        }
      }

      &[class~='Mui-selected'] {
        [class~='MuiTypography-root'] {
          color: $simplewhite;
          font-weight: 700;
        }
        background-color: transparent;
        border-radius: 5px 10px 10px 5px;
        border-left: 5px solid white;
        [class~='MuiListItemIcon-root'] {
          svg {
            color: $simplewhite;
          }
        }
      }
    }
  }

  .avatar {
    margin: 50px $borderRadius $borderRadius $borderRadius;
    justify-content: center;
    width: auto;
    height: auto;
    max-width: 250px;
    height: 124px;
    border-radius: 15px;
    img {
      border-radius: 15px;
      object-fit: contain;
    }
  }
  .companyName {
    margin-top: 30px;
  }
  [class~='MuiList-root'] {
    margin-top: 60px;
  }
  .logoOp {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    img {
      height: auto;
      width: 180px;
    }
  }
}

.mainContainer {
  transition: margin $animTime ease-out;
  width: 100%;
  [class~='MuiContainer-root'] {
    padding-left: 0;
    padding-right: $borderRadius;
  }
  padding: 20px 16px;
  overflow-x: hidden;
  @media only screen and (max-width: $breakpointMedium) {
    padding: 20px 10px 66px;
    margin-left: 0;
    [class~='MuiContainer-root'] {
      padding: 0;
      margin: 0;
    }
  }
}

.spinnerContainer {
  margin: auto;
}

.cardSpinnerContainer {
  margin: 0 auto;
}

.whiteSpinnerContainer {
  margin: auto;
  [class~='MuiCircularProgress-root'] {
    color: white;
  }
}

.mobileHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  [class~='MuiTypography-root'] {
    color: white;
    font-weight: 600;
    font-size: 16px;
  }
  .avatar {
    justify-content: center;
    width: auto;
    height: auto;
    max-width: 120px;
    height: 80px;
    border-radius: 15px;
    img {
      border-radius: 15px;
      object-fit: contain;
    }
  }
}

.dashBody {
  max-width: 100%;
  height: calc(100vh - 104px);
  padding: 32px 46px;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;

  transition: width $animTime ease-out;

  @media only screen and (max-width: $breakpointMedium) {
    margin: 0;
    height: calc(100vh - 96px);
    border-radius: 16px;
    margin-top: 25px;
    padding: 20px 10px;
    height: unset;
  }
}

.responseContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $simplewhite;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  .topBar {
    position: absolute;
    height: 45px;
    background: #4653e3;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    .opLogo {
      height: 30px;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 50px;
    img {
      width: 200px;
      max-width: 90vw;
      max-height: 320px;
      @media only screen and (max-width: $breakpointMedium) {
        width: 150px;
      }
    }
    [class~='MuiTypography-root'] {
      color: #1f1f1f;
      font-weight: 400;
      font-size: 16px;
    }
    @media only screen and (max-width: $breakpointMedium) {
      padding: 5px 20px;
    }
  }
}

.tabTitle {
  color: #1f1f1f;
  [class~='MuiTypography-root'] {
    font-weight: 600;
    font-size: 30px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    [class~='MuiTypography-root'] {
      font-weight: 600;
      font-size: 25px;
    }
  }
}

.planName {
  color: #1f1f1f;
  [class~='MuiTypography-root'] {
    font-weight: 600;
    font-size: 22px;
  }
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 104px);
  border-radius: 10px;
  box-shadow: none;
  background: white;
  padding: 32px 46px;
  overflow-x: hidden;
  .description {
    [class~='MuiTypography-root'] {
      color: #a9a9aa;
      font-weight: 400;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin: 0;
    border-radius: 16px;
    margin-top: 25px;
    padding: 20px 10px;
    width: calc(100% - 20px);
    height: unset;
    min-height: calc(100vh - 210px);
  }
}

.buildingIconCircle {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardsBox {
  background: #fcfcfc;
  border-radius: 16px;
  border: 1px solid #dddeeb;
  margin-top: 30px;
  padding: 30px 30px 50px;
  width: unset;
  [class~='MuiTypography-root'] {
    color: #1f1f1f;
    font-weight: 600;
    font-size: 22px;
  }
  .cardsRow {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
  }
  .box {
    border: 1px solid #dddeeb;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    max-width: 406px;
    max-height: 300px;
    padding: 15px 30px 20px;
    .actions {
      display: flex;
      flex-direction: row;
      width: 100%;
      .warningRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .message {
          display: flex;
          flex-direction: row;
          gap: 5px;
          align-items: center;
          [class~='MuiTypography-root'] {
            color: $errorColor;
            font-weight: 400;
            font-size: 14px;
          }
          .warningIcon {
            color: $errorColor;
            height: 15px;
            width: 15px;
          }
        }
      }
    }
    .delete {
      cursor: pointer;
      align-self: flex-end;
      margin-left: 6px;
      text-decoration: underline;
      text-decoration-color: #1f1f1f;
      [class~='MuiTypography-root'] {
        color: #1f1f1f;
        font-weight: 400;
        font-size: 14px;
      }
      &:hover {
        [class~='MuiTypography-root'] {
          font-weight: 600;
        }
      }
    }
    .card {
      margin-top: 15px;
      height: 200px;
      width: 345px;
      background-image: url('https://storage.googleapis.com/onlypays-public/assets/images/card_background.png');
      background-size: cover;
      border-radius: 30px;
      position: relative;
      .cardNumber {
        position: absolute;
        left: 34px;
        bottom: 23px;
        [class~='MuiTypography-root'] {
          font-size: 22px;
          font-weight: 400;
          color: white;
        }
        @media only screen and (max-width: $breakpointMedium) {
          [class~='MuiTypography-root'] {
            font-size: 18px;
          }
        }
      }
      .cardIcon {
        width: 60px;
        height: auto;
        position: absolute;
        right: 30px;
        top: 15px;
        img {
          width: 60px;
        }
        [class~='MuiTypography-root'] {
          font-size: 18px;
          font-weight: 500;
          color: white;
        }
      }
    }
    .pac {
      margin-top: 15px;
      height: 200px;
      width: 345px;
      background-image: url('https://storage.cloud.google.com/onlypays-public/assets/images/green_card_background.png');
      background-size: cover;
      border-radius: 30px;
      position: relative;
      .cardNumber {
        position: absolute;
        left: 34px;
        bottom: 23px;
        [class~='MuiTypography-root'] {
          font-size: 22px;
          font-weight: 400;
          color: white;
        }
        [class~='MuiTypography-subtitle1'] {
          font-size: 18px;
        }
        @media only screen and (max-width: $breakpointMedium) {
          [class~='MuiTypography-root'] {
            font-size: 18px;
          }
        }
      }
      .cardIcon {
        height: auto;
        position: absolute;
        right: 30px;
        top: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        img {
          width: 60px;
        }
        [class~='MuiTypography-root'] {
          font-size: 18px;
          font-weight: 500;
          color: white;
        }
        [class~='MuiTypography-subtitle1'] {
          font-size: 13px;
          color: #39bdaf;
          background-color: white;
          border-radius: 14px;
          padding: 0px 10px;
        }
      }
    }
  }
  .newCardBox {
    cursor: pointer;
    min-width: 406px;
    padding: unset;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    [class~='MuiTypography-root'] {
      font-weight: 400;
      font-size: 16px;
    }
    height: 300px;
    .plusIcon {
      height: 40px;
      width: 40px;
    }
    &:hover {
      border: 1px solid #bebebe;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    padding: 15px 10px 30px;
    .box {
      width: calc(100% - 22px);
      padding: 10px 10px 20px;
      justify-content: center;
      align-items: center;
    }
    .card {
      max-width: 100%;
    }
    .cardsRow {
      overflow-x: hidden;
      flex-wrap: wrap;
      max-width: 100%;
      justify-content: center;
    }
    .newCardBox {
      min-width: calc(100% - 22px);
      height: 236px;
    }
  }
}

.popupContainer {
  width: 100%;
  margin-top: 30px;
  [class~='MuiRadio-root'] {
    &[class~='Mui-checked'] {
      color: #606060;
    }
  }
  .titleHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
    .icon {
      height: 40px;
      min-width: 40px;
      .buildingIcon {
        color: white;
      }
    }
    [class~='MuiTypography-root'] {
      color: #1f1f1f;
      font-weight: 600;
      font-size: 18px;
    }
  }
  .updateCardTitle {
    [class~='MuiTypography-root'] {
      color: #5f5f5f;
      font-weight: 400;
      font-size: 16px;
    }
  }
  .cardsBox {
    max-width: 100%;
    background: #fcfcfc;
    border: 1px solid #dddeeb;
    border-radius: 16px;
    padding: 15 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    [class~='MuiTypography-root'] {
      color: #5f5f5f;
      font-weight: 400;
      font-size: 14px;
    }
    .cardsColumn {
      width: 60%;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 13px;
      .card {
        border: 1px solid #dddeeb;
        border-radius: 16px;
        padding: 13px 20px;
        max-height: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        margin-bottom: 10px;
        width: 210px;
        [class~='MuiFormControlLabel-root'] {
          margin-right: 0;
          width: min-content;
        }
        .cardRow {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 16px;
          white-space: nowrap;
          .cardIcon {
            color: #1f1f1f;
          }
        }
        .addCard {
          margin: auto;
          min-height: 24px;
          cursor: pointer;
          p {
            margin-bottom: 3px;
          }
          @media only screen and (max-width: $breakpointMedium) {
            min-height: 42px;
            margin: 0 auto;
          }
        }
      }
      .newCard {
        cursor: pointer;
        &:hover {
          border: 1px solid #b0b0b0;
        }
      }
      [class~='MuiFormGroup-root'] {
        max-width: 100%;
      }
      @media only screen and (max-width: $breakpointMedium) {
        max-width: 100%;
        width: unset;
        .card {
          width: unset;
          max-width: 100%;
          padding: 13px 15px;
          gap: 12px;
          width: 220px;
        }
      }
    }
    .button {
      cursor: pointer;
      width: 150px;
      height: 35px;
      border-radius: 28px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      [class~='MuiTypography-root'] {
        color: white;
        font-weight: 400;
        font-size: 14px;
      }
      &:hover {
        filter: brightness(85%);
      }
    }
    @media only screen and (max-width: $breakpointMedium) {
      padding: 20px 10px;
    }
  }
}

.titlePopup {
  [class~='MuiTypography-root'] {
    color: #838383;
    font-weight: 400;
    font-size: 16px;
  }
}

.mobileNavbar {
  [class~='Mui-selected'] {
    font-weight: 500;
  }
  [class~='MuiButtonBase-root'] {
    transition: unset;
  }
}

.paymentsTable {
  border-radius: 16px;
  border: 1px solid #dddeeb;
  background: #fcfcfc;
  padding: 5px 20px 20px;
  margin-top: 35px;
  .tableHeader {
    [class~='MuiTypography-root'] {
      color: #a9a9aa;
      font-weight: 400;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .tableRow {
    [class~='MuiTypography-root'] {
      color: #1f1f1f;
      font-weight: 400;
      font-size: 18px;
    }
    [class~='MuiTableCell-root'] {
      border-top: 1px solid rgba(224, 224, 224, 1);
      border-bottom: none;
    }
  }
  .amount {
    [class~='MuiTypography-root'] {
      font-weight: 600;
    }
  }
  .collapseIcom {
    min-height: 27px;
    width: 27px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      color: white;
    }
  }
  .detailLink {
    display: flex;
    width: 100%;
    justify-content: center;
    [class~='MuiTypography-root'] {
      color: #1f1f1f;
      font-weight: 400;
      font-size: 12px;
      text-decoration: underline;
    }
  }
  .collapseRow {
    padding: 0;
    [class~='MuiTableCell-root'] {
      border: none;
      padding: 5px 16px;
    }
  }
  .collapseBox {
    border-radius: 16px;
    border: 1px solid #dddeeb;
    .detailTable {
      .tableHeader {
        [class~='MuiTypography-root'] {
          color: #1f1f1f;
          font-weight: 400;
          font-size: 12px;
        }
      }
      .tableRow {
        [class~='MuiTypography-root'] {
          color: #1f1f1f;
          font-weight: 400;
          font-size: 16px;
        }
        [class~='MuiTableCell-root'] {
          border-top: 1px solid rgba(224, 224, 224, 1);
          border-bottom: none;
        }
      }
      .amount {
        [class~='MuiTypography-root'] {
          font-weight: 600;
        }
      }
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    padding: 5px 0 10px;
    .tableRow {
      [class~='MuiTypography-root'] {
        font-size: 15px;
      }
    }
    .collapseRow {
      [class~='MuiTableCell-root'] {
        padding: 5px 8px;
      }
    }
  }
}

.mobilePayment {
  border-radius: 16px;
  border: 1px solid #dddeeb;
  background: #fcfcfc;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 10px 0;
  width: 100%;
  margin-top: 15px;
  [class~='MuiTypography-root'] {
    color: #1f1f1f;
    font-weight: 400;
    font-size: 16px;
  }
}

.paymentDetailPopup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
  [class~='MuiTypography-root'] {
    color: #1f1f1f;
    font-weight: 400;
    font-size: 16px;
  }
  .paymentSummary {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    .paymentDetail {
      display: flex;
      border-radius: 12.838px;
      border: 1px solid #dddeeb;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      margin-bottom: 8px;
      [class~='MuiTypography-root'] {
        color: #1f1f1f;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}

.kushkiCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px 80px;
  .form {
    margin-top: 50px;
  }
  .kushkiLogo {
    width: 100px;
    height: auto;
    margin: 15px auto;
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    margin-top: 40px;
  }
}

.kushkiCardContainerPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 20px;
  [class~='MuiFormHelperText-root'] {
    margin: 3px 0 0;
  }
  .form {
    margin-top: 30px;
  }
  .kushkiLogo {
    width: 100px;
    height: auto;
    margin: 10px auto;
  }
}

.klapFormContainer {
  position: fixed;
  background: rgb(27 26 30 / 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10000;
  .formContainer {
    padding: 30px 30px 35px;
    width: 30%;
    height: auto;
    background-color: white;
    border: 1px solid grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
    z-index: 1001;
    gap: 15px;
    .row {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    .inputField {
      width: 100%;
      height: auto;
    }
    .inputFieldSelect {
      width: 100%;
      height: auto;
      [class~='MuiList-rootroot'] {
        z-index: 1000;
      }
    }
    .subscribeCard {
      margin-top: 20px;
      width: 140px;
      background-color: $primaryBlue;
      color: white;
      height: 35px;
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 500;
      margin: auto;
      [class~='MuiCircularProgress-root'] {
        color: white;
      }
      &:hover {
        background-color: $primaryBlue;
        filter: brightness(85%);
      }
    }
    @media only screen and (max-width: $breakpointMedium) {
      width: 80%;
      padding: 15px 10px 25px;
    }
  }
}

.rutContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;
  margin: auto;
  margin-top: 20px;
  button {
    margin: auto;
    color: white;
    width: 200px;
    box-shadow: none;
  }
  .circularLoader {
    color: white;
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 90%;
  }
}
