@use 'sass:color';
@import '../../../common/styles/variables.module.scss';

.start {
  margin: 20px;
  color: $simplewhite;
  .startTitle {
    margin-left: 15px;
    font-weight: bold;
  }
}

.paperHeader {
  color: $simplewhite;
  [class~='MuiTypography-root'] {
    font-weight: 700;
    margin-bottom: 20px;
    margin-left: 20px;
  }
}

.containerGrid {
  max-height: calc($containerHeight + 16px + 15px);
  gap: 16px;
  margin-top: 10px;
}

.linkContainer {
  height: $containerHeight;
  margin-bottom: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: $borderRadius;
  background: $simplewhite;
  [class~='main'] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  [class~='mainImage'] {
    max-height: $containerImageMaxHeight;
    margin-top: 5px;
  }
  [class~='MuiButton-root'] {
    background-color: #1f7e7d;
    border-radius: $borderRadius;
    width: 75%;
    position: relative;
    bottom: calc(15px + $borderRadius);
  }
}

.firstLinkContainer {
  height: $containerHeight;
  margin-bottom: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: $borderRadius;
  background: $simplewhite;
  [class~='main'] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  [class~='mainImage'] {
    max-height: $containerImageMaxHeight;
    margin-top: 5px;
  }
  [class~='MuiButton-root'] {
    background-color: #1f7e7d;
    border-radius: $borderRadius;
    width: 75%;
    position: relative;
    bottom: calc(15px + $borderRadius);
  }
}

.secondLinkContainer {
  height: $containerHeight;
  margin-bottom: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: $borderRadius;
  background: $simplewhite;
  [class~='main'] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  [class~='mainImage'] {
    max-height: $containerImageMaxHeight;
    margin-top: 20px;
  }
  [class~='MuiButton-root'] {
    background-color: #1f7e7d;
    border-radius: $borderRadius;
    width: 75%;
    position: relative;
    bottom: calc(15px + $borderRadius);
  }
}

.mainPaper {
  padding: 2em;
  border-radius: 0;
  // margin: 5%;
  @media only screen and (max-width: $breakpointMedium) { 
    padding: 15px;
  }
}

.mainEditContainer{
  margin: auto;
}

.infoPaper {
  width: 100%;
  .mainEditContainer {
    width: 80%;
  }
  .title {
    color: $primaryDarkerColor;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    [class~='MuiTypography-root'] {
      font-weight: 800;
    }
  }
  .titleAdvanced {
    color: $primaryDarkerColor;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    [class~='MuiTypography-root'] {
      font-weight: 800;
    }
    @media only screen and (max-width: $breakpointMedium) {
      justify-content: unset;
      flex-direction: column;
    }
  }

  .subtitle {
    color: $primaryDarkerColor;
    margin-bottom: 10px;
  }

  .advancedSettings {
    color: $primaryDarkerColor;
    display: flex;
    align-items: center;
  }

  .paymentPlanEditContainer {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    @media only screen and (max-width: $breakpointMedium) {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    padding: 15px;
  }
}

.image {
  min-height: 150px;
  min-width: 150px;
  max-height: 150px;
  max-width: 150px;
  margin-left: 20px;
  .containerProductImage {
    width: 95%;
    height: 95%;
    border-radius: 50%;
    background-color: #30a3ab;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0 !important;

    .buttonProductImage {
      z-index: 1;
      min-width: 0;
      border-radius: 50%;
      height: fit-content;
      width: fit-content;
      background: rgba(88, 177, 194, 0.7);
      position: relative;
      top: 20px;
    }

    .logoProduct {
      position: relative;
      z-index: 0;
      height: 100%;
      width: 100%;
      top: 30px;
      border-radius: 50%;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    align-self: center;
    margin: 0;
    .containerProductImage {
      min-height: 100px;
      min-width: 100px;
      max-height: 100px;
      max-width: 100px;
      margin: auto;
    }
  }
}

.productOptions {
  margin-top: 15px;
  margin-bottom: 10px;
}

.fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  @media only screen and (max-width: $breakpointMedium) {
    margin-left: 0px;
  }
  .field {
    color: $primaryDarkerColor;
    // width: 80%;
    width: 100%;
    margin-bottom: 10px;
    [class~='MuiOutlinedInput-input'] {
      padding: 10px;
    }
  }
}

.fieldContainer {
  color: $primaryDarkerColor;
  [class~='MuiOutlinedInput-input'] {
    padding: 10px;
  }
}

.content {
  .options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    [class~='MuiButtonGroup-root'] {
      margin-right: 10px;
    }
  }
  .clientForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    .clientFormRow {
      min-width: 100%;
      display: flex;
      @media only screen and (max-width: $breakpointMedium) {
        flex-direction: column;
      }
      .clientFormCell {
        color: $primaryDarkerColor;
        width: 100%;
        margin-right: 10px;
        @media only screen and (max-width: $breakpointMedium) {
          margin-bottom: 5px;
        }
      }
    }
    [class~='MuiOutlinedInput-root']:hover [class~='MuiOutlinedInput-notchedOutline'] {
      border-color: unset;
    }
  }
}

.drawer {
  min-width: 600px;
  overflow: scroll;

  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 50px;
    max-width: 100vw;
    min-width: 100vw;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    font-size: 30px;
    color: $primaryDarkerColor;
    .icon:hover {
      cursor: pointer;
    }
    [class~='MuiTypography-root'] {
      font-size: 30px;
      color: $primaryDarkerColor;
    }
    @media only screen and (max-width: $breakpointMedium) {
      [class~='MuiTypography-root'] {
        font-size: 25px;
        color: $primaryDarkerColor;
      }
      .icon {
        max-width: 15px;
      }
    }
  }

  .drawerRow {
    display: flex;
    gap: 10px;
    max-width: 90%;
    margin-left: 5%;
    margin-bottom: 20px;
    [class~='MuiOutlinedInput-input'] {
      padding: 10px;
    }
    @media only screen and (max-width: $breakpointMedium) {
      flex-direction: column;
    }
    .drawerItem {
      [class~='MuiTypography-root'] {
        font-size: 16px;
        line-height: 1.75rem;
        color: $primaryDarkerColor;
      }
      min-width: 45%;
    }
    .deleteRow {
      margin-top: 1.75rem;
      min-width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      :hover {
        cursor: pointer;
      }
      @media only screen and (max-width: $breakpointMedium) {
        margin-top: 0.75rem;
        margin-bottom: 10px;
      }
    }
  }

  .actions {
    margin-left: 20px;
  }
}

.statementsContainer {
  .statementRow {
    width: 75%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 7px;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    .trashicon {
      :hover {
        cursor: pointer;
      }
    }
    @media only screen and (max-width: $breakpointMedium) {
      flex-direction: column;
      width: 90%;
      padding: 5px 15px 10px;
      [class~='MuiTextField-root'] {
        margin: 5px 0 15px;
        width: 90%;
      }
    }
  }
}

.containerGrid {
  max-height: calc($containerHeight + 16px + 15px);
  gap: 16px;
}

.infoContainerTitle {
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 700 !important;
  color: $primaryDarkerColor !important;
}


.statementForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.clientPortalLink {
  color: #2c729d;
  font-weight: 400;
}

.mailButton {
  color: white;
  font-weight: 500;
  background-color: #2c729d;
  height: 30px;
  margin-top: 6px;
  &:hover {
    background-color: #22648d;
  }
}

.toButton {
  min-width: 100%;
  background: linear-gradient(180deg, #004a77 0%, #003657 100%);
  border-radius: $borderRadius;
  margin: 0px auto 15px auto;
  min-height: 45px;
}

.excelImportContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 66%;
  @media only screen and (max-width: $breakpointMedium) {
    width: 80%;
  }
  .excelImportButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: $primaryDarkerColor;
    padding: 12px;
    margin-top: 10px;
    [class~='MuiTypography-root'] {
      color: $primaryDarkerColor;
    }
    @media only screen and (max-width: $breakpointMedium) {
      padding: 5px;
    }
  }
}

.actionsEdit {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
}

.paymentsDrawer {
  padding: 20px 30px;
  .closePopup {
    display: flex;
    justify-content: flex-end;
    .xIcon {
      width: 15px;
      cursor: pointer;
    }
  }
}

.drawerContainer {
  .drawer {
    padding: 15px;
    min-width: 45vw;
    width: 45vw;
    .closePopup {
      display: flex;
      justify-content: flex-end;
      .xIcon {
        width: 15px;
        cursor: pointer;
      }
    }
    .inputField {
      width: 100%;
    }
    .notesInput {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      margin-bottom: 25px;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    min-width: 100vw;
    width: 100vw;
    z-index: 12000;
    .drawer {
      padding: 15px 8px;
      margin: 0;
      width: calc(100vw - 16px);
      .inputField {
        width: calc(100vw - 16px);
      }
    }
  }
}

.reportRow {
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-top: 15px;
  padding: 10px 40px;
  [class~='MuiTypography-root'] {
    font-size: 14px;
    text-align: center;
  }
  cursor: pointer;
  @media only screen and (max-width: $breakpointMedium) {
    padding: 5px 0px 5px 10px;
    [class~='MuiTypography-root'] {
      text-align: center;
    }
  }
  &:hover {
    background-color: #e5e5e5;
  }
}

.required {
  font-weight: 700;
  color: $errorColor;
}

.linkTo {
  cursor: alias;
  white-space: nowrap;
  a {
    color: #2c729d;
  }
}
.subtitle{
  margin: 10px 0px;
}
.marginable{
  margin: 2em 0px;
}
.littleMarginable{
  margin: 1em 0px;
}
.buttonMargin{
  margin-top: 1em;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: wait;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.principalIcon {
  font-size: 10rem;
}

.loaderContainer {
  position: fixed;
  background: color.adjust($lightergray, $alpha: -0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 2px);
  height: 100vh;
  border-radius: $borderRadius;
  z-index: 10000;

  img {
    width: 50vw;
    max-width: 500px;
    animation: pulse 0.666s infinite;
    animation-direction: alternate;
    animation-name: pulse;
  }

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}