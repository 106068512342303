.logoContainer {
  text-align: center;
  font-size: 30px;
  margin-bottom: 0px;
  margin-top: 2rem;
}

.logo {
  max-width: 100%;
  // width: 350px; el ancho se lo da el componente padre
  height: auto;
}

.pointer {
  cursor: pointer;
}