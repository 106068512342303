@use 'sass:color';
@import '../../../../common/styles/variables.module';

.loaderContainer {
  position: absolute;
  background: color.adjust($lightergray, $alpha: 0);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  img {
    width: 50vw;
    max-width: 500px;
    animation: pulse 0.666s infinite;
    animation-direction: alternate;
    animation-name: pulse;
  }

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

.responseContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  [class~='MuiTypography-root'] {
    font-family: 'Poppins';
  }
}

.backgroundWhite {
  background-color: white;
}

.blueBg {
  background: linear-gradient(180deg, #4653e3 0%, #272e7d 100%) !important;
}

.responseBg1 {
  background-image: url(https://storage.googleapis.com/onlypays-public/assets/images/response_bg_1.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.responseBg2 {
  background-image: url(https://storage.googleapis.com/onlypays-public/assets/images/response_bg_2.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.responseBg3 {
  background-image: url(https://storage.googleapis.com/onlypays-public/assets/images/response_bg_3.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.responseBg4 {
  background-image: url(https://storage.googleapis.com/onlypays-public/assets/images/response_bg_4.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.responseBg5 {
  background-image: url(https://storage.googleapis.com/onlypays-public/assets/images/response_bg_5.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.redBgLinear {
  background: linear-gradient(180deg, #dd5151 0%, #772c2c 100%) !important;
}

.redBgRadial {
  background: radial-gradient(circle, #dd5151, #9e4747) !important;
}

.greenBg {
  background: linear-gradient(180deg, #39bdaf 0%, #1a5751 100%) !important;
}

.yellowBg {
  background: linear-gradient(180deg, #f8bc08 0%, #926f05 100%) !important;
}

.response {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.responseContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 100%;
  // height: 100%;
  padding: 20px 0;
  @media only screen and (max-width: $breakpointMedium) {
    margin: 0;
    gap: 35px;
  }
}

.zpLogoContainer {
  margin-top: 30px;
  .zpLogo {
    width: 150px;
    height: auto;
    margin: auto;
  }
  a {
    width: 200px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 80px;
    .zpLogo {
      width: 150px;
    }
    a {
      width: 150px;
    }
  }
}

.icon {
  color: white;
  height: 90px;
  width: 90px;
  font-weight: 100 !important;
  @media only screen and (max-width: $breakpointMedium) {
  }
}

.logo {
  height: auto;
  width: auto;
  max-width: 250px;
  max-height: 120px;
  border-radius: 15px;
}

.strongText {
  font-size: 20px;
  font-weight: 600;
}
