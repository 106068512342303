@import '../../../common/styles/variables.module.scss';
@import '../../../common/styles/common.module.scss';

.start {
  margin: 20px;
  color: $simplewhite;
  .startTitle {
    margin-left: 15px;
    font-weight: bold;
  }
}

.toButton {
  width: 100%;
  background: linear-gradient(180deg, #ECB91B 0%, #B87D3D 100%);
  border-radius: $borderRadius;
  margin: 0 auto 15px auto;
  min-height: 45px;
}

.containerGrid {
  max-height: calc($containerHeight + 16px + 15px);
  gap: 16px;
}

.firstInfoContainer {
  @extend .baseInfoContainer;
  [class~=MuiTypography-root] {
    color: $warningColor;
  }
  [class~=titleIcon1] {
    background: linear-gradient(180deg, #EBB513 0%, rgba(255, 199, 39, 0.32) 100%)
  }
  [class~=footer] {
    background-color: $warningColor;
  }
}

.linkContainer {
  height: $containerHeight;
  margin-bottom: 15px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: $borderRadius;
  background: linear-gradient(284.6deg, #D19E00 0%, rgba(255, 209, 78, 0.79) 100%);
  [class~=main] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  [class~=mainImage] {
    max-height: $containerImageMaxHeight;
    margin-top: 5px;
  }
  [class~=MuiButton-root] {
    background-color: $yellowColor;
    border-radius: $borderRadius;
    width: 75%;
    position: relative;
    bottom: calc(15px + $borderRadius);
  }
}

.reportRow {
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-top: 15px;
  padding: 10px 40px;
  [class~='MuiTypography-root'] {
    font-size: 14px;
    text-align: center;
  }
  cursor: pointer;
  @media only screen and (max-width: $breakpointMedium) {
    padding: 5px 0px 5px 10px;
    [class~='MuiTypography-root'] {
      text-align: center;
    }
  }
  &:hover {
    background-color: #e5e5e5;
  }
}

.excelImportContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 66%;
  @media only screen and (max-width: $breakpointMedium) {
    width: 80%;
  }
  .excelImportButton {
    display: flex;
    align-items: stretch;
    justify-content: center;
    border-color: $primaryDarkerColor;
    padding: 12px;
    margin-top: 10px;
    svg {
      margin-right: auto;
    }
    [class~='MuiTypography-root'] {
      color: $primaryDarkerColor;
    }
    @media only screen and (max-width: $breakpointMedium) {
      padding: 5px;
    }
  }
  .loadButton {
    display: flex;
    align-items: stretch;
    justify-content: center;
    white-space: pre-wrap;
    [class~='MuiTypography-root'] {
      color: $primaryDarkerColor;
    }
    @media only screen and (max-width: $breakpointMedium) {
      padding: 5px;
    }
  }
}
