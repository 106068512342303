@use 'sass:color';
@import '../../../common/styles/variables.module';
@import '../Client.module.scss';

.indexContainer {
  width: calc(100% - 92px);
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 104px);
  padding: 32px 46px;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: none;
  .description {
    [class~='MuiTypography-root'] {
      color: #a9a9aa;
      font-weight: 400;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin: 0;
    border-radius: 16px;
    margin-top: 25px;
    padding: 20px 10px;
    width: calc(100% - 20px);
    min-height: calc(100vh - 210px);
    height: unset;
  }
}

.backRow {
  margin-bottom: 20px;
  width: min-content;
  cursor: pointer;
  .icon {
    height: 20px;
    width: 20px;
  }
}

.plansContainer {
  margin-top: 30px;
}

.headerRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  .icon {
    min-width: 50px;
    height: 50px;
    margin-right: 20px;
    .buildingIcon {
      color: white;
      height: 25px;
      width: 25px;
    }
  }
}

.cardContainer {
  max-width: 100%;
  background: #fcfcfc;
  border: 1px solid #dddeeb;
  border-radius: 16px;
  padding: 15px 24px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .infoPaymentPlanContainer {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .imageContainer {
    margin: auto;
    height: 140px;
    min-width: 140px;
    margin-right: 15px;
    .imagePaymentPlan {
      height: 100%;
      width: 100%;
      border-radius: 24px;
      margin: auto;
    }
  }
  .infoRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    [class~='MuiTypography-root'] {
      line-height: 18px;
    }
    @media only screen and (max-width: $breakpointMedium) {
      flex-direction: column;
      gap: 10px;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    padding: 15px 10px 20px;
    margin-bottom: 12px;
  }
}

.title {
  color: #a8a8a8;
  font-size: 12px;
  margin-top: 5px;
}

.name {
  color: #1f1f1f;
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
}

.cardInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 40px;
  margin-bottom: 20px;
  @media only screen and (max-width: $breakpointMedium) {
    margin-bottom: 0;
  }
}

.editButton {
  border: 1px solid;
  border-radius: 25px;
  max-width: 110px;
  max-height: 24px;
  padding: 0 13px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  margin-top: 20px;
  [class~='MuiTypography-root'] {
    font-size: 12px;
    line-height: auto;
    margin-top: 3px;
  }
  &:hover {
    background-color: #f1f1f1;
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 5px;
    padding: 2px 13px;
    [class~='MuiTypography-root'] {
      margin-top: 0;
    }
  }
}

.showButton {
  border: 1px solid;
  border-radius: 25px;
  max-width: 110px;
  max-height: 24px;
  padding: 5px 13px;
  color: #1f1f1f;
  font-size: 12px;
  margin-bottom: 15px;
  cursor: pointer;
  white-space: nowrap;
  [class~='MuiTypography-root'] {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
  }
  [class~='MuiListItemIcon-root'] {
    min-width: 20px;
  }
  &:hover {
    background-color: #c3c3c3;
  }
}

.nameContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.letterCoontainer {
  height: 50px;
  min-width: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  [class~='MuiTypography-root'] {
    font-size: 30px;
    color: white;
    font-weight: 600;
  }
}

.mobileTopCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.paymentRow {
  display: flex;
  flex-direction: row;
  gap: 15px;
  [class~='MuiListItem-root'] {
    padding: 0;
  }
  .editLink {
    [class~='MuiTypography-root'] {
      font-size: 12px;
      line-height: 12px;
      text-align: center;
    }
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      [class~='MuiTypography-root'] {
        font-weight: 600;
      }
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    gap: 8px;
  }
}

.installmentsBox {
  max-width: 100%;
  border: 1px solid #dddeeb;
  border-radius: 16px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  .debtTitle {
    color: #1f1f1f;
    font-weight: 600;
    font-size: 22px;
  }
  .installmentsInfo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: $breakpointMedium) {
      display: unset;
    }
  }
  .installmentsRow {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    .advice {
      max-width: 140px;
      max-height: 18px;
      border-radius: 8px;
      background-color: #dd5151;
      text-align: center;
      padding: 5px 14px;
      [class~='MuiTypography-root'] {
        color: white;
        font-size: 12px;
        line-height: unset;
        text-align: center;
      }
    }
    @media only screen and (max-width: $breakpointMedium) {
      margin-top: 5px;
    }
  }
  .number {
    color: #1f1f1f;
    font-size: 18px;
  }
  [class~='MuiListItemIcon-root'] {
    min-width: 0;
    margin-right: 8px;
  }
  .leftCol {
    @media only screen and (max-width: $breakpointMedium) {
      width: 100%;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
}

.singlePaymentText {
  color: #1f1f1f;
  font-size: 18px;
  margin-bottom: 20px;
}

.showInstallmentsBox {
  flex-direction: row;
  justify-content: space-between;
}

.statementsList {
  margin-top: 0;
  width: 95%;
  [class~='MuiCheckbox-root'] {
    padding: 9px 0 9px 10px;
  }
  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
    [class~='MuiTableCell-root'] {
      padding: 0 2px;
    }
    [class~='MuiCheckbox-root'] {
      padding: 9px;
    }
  }
  .row {
    max-width: 60px;
    @media only screen and (max-width: $breakpointMedium) {
      max-width: none;
      text-align: center;
    }
  }
  .debtRow {
    [class~='MuiTypography-root'] {
      color: #dd5151;
    }
  }
  .dateCell {
    padding-left: 1em;
  }
  [class~='MuiTableCell-root'] {
    border-bottom: none;
  }
  [class~='MuiTable-root'] {
    min-width: unset;
  }
  .nameRow {
    [class~='MuiTypography-body1'] {
      font-size: 1em;
      text-align: center;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
}
.truncateDiv {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
.truncateText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}


.statementsListHeader{
  text-align: center;
  font-weight: bold;
}

.viewMoreButton {
  text-decoration: underline;
  width: min-content;
  margin: auto;
  white-space: nowrap;
  cursor: pointer;
  [class~='MuiTypography-root'] {
    margin-top: 5px;
    font-size: 16px;
    color: #1f1f1f;
  }
}

.actions {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  @media only screen and (max-width: $breakpointMedium) {
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
  }
}
.detailButton {
  cursor: pointer;
  background-color: #39bdaf;
  color: white;
  max-width: 200px;
  height: 40px;
  border-radius: 28px;
  text-align: center;
  padding: 10px 30px;
  align-self: center;
  white-space: nowrap;
  &:hover {
    background-color: #269e92;
  }
  @media only screen and (max-width: $breakpointMedium) {
    max-width: 150px;
    font-size: 12px;
    padding: 8px 10px;
    [class~='MuiListItemIcon-root'] {
      min-width: 10px;
      margin-right: 8px;
    }
  }
}

.tabs {
  margin-top: 15px;
  [class~='MuiTab-root'] {
    font-weight: 300;
    font-size: 18px;
    text-transform: none;
  }
  [class~='Mui-selected'] {
    font-weight: 600;
    font-size: 20px;
    border-bottom: 2px solid;
  }
  [class~='MuiTabs-indicator'] {
    background: inherit;
  }
  @media only screen and (max-width: $breakpointMedium) {
    margin-top: 10px;
    [class~='MuiTabs-flexContainer'] {
      display: flex;
      justify-content: center;
    }
    [class~='MuiTab-root'] {
      font-size: 14px;
    }
    [class~='Mui-selected'] {
      font-size: 18px;
    }
  }
}

.installmentsCard {
  background: #fcfcfc;
  border: 1px solid #dddeeb;
  border-radius: 16px;
  max-width: 100%;
  padding: 25px;
  margin-top: 35px;
  .adviceFilled {
    max-width: 140px;
    max-height: 18px;
    border-radius: 8px;
    background-color: #dd5151;
    text-align: center;
    padding: 5px 14px;
    [class~='MuiTypography-root'] {
      color: white;
      font-size: 12px;
      line-height: unset;
      text-align: center;
    }
  }
  [class~='MuiTable-root'] {
    min-width: unset;
  }
  .table {
    margin-top: 25px;
    @media only screen and (max-width: $breakpointMedium) {
      margin-top: 20px;
      [class~='MuiTableCell-root'] {
        padding: 14px 2px;
      }
    }
  }
  .rowElement {
    max-width: 60px;
    @media only screen and (max-width: $breakpointMedium) {
      max-width: none;
      text-align: center;
    }
  }
  .tableHeader {
    [class~='MuiTableCell-root'] {
      font-weight: 600;
    }
  }
  .hideDivider {
    [class~='MuiTableCell-root'] {
      border-bottom: none;
    }
  }
  .tag {
    border-radius: 28px;
    padding: 6px 15px;
    color: white;
    text-align: center;
    max-height: 24px;
    max-width: 80px;
    line-height: 12px;
    [class~='MuiTypography-root'] {
      line-height: unset;
      font-size: 12px;
    }
    @media only screen and (max-width: $breakpointMedium) {
      padding: 6px 10px;
      width: 60px;
      margin: auto;
    }
  }
  .spinnerContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  [class~='MuiCheckbox-root'] {
    height: 20px;
    &[class~='Mui-checked'] {
      color: unset;
    }
  }
  @media only screen and (max-width: $breakpointMedium) {
    padding: 10px;
    margin-top: 20px;
  }
}

.tablePagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  [class~='MuiButtonBase-root'] {
    background-color: #4653e3;
  }
}

.toPayContainer {
  position: fixed;
  bottom: 0;
  width: calc(100% - 472px);
  left: 436px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  border-radius: 16px;
  background: #fff;
  padding: 30px 10px;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
  [class~='MuiTypography-root'] {
    font-size: 18px;
    color: #1f1f1f;
  }
  .totalAmount {
    [class~='MuiTypography-root'] {
      font-size: 25px;
      font-weight: 600;
    }
  }
  .statementRow {
    height: 50px;
    [class~='MuiTypography-root'] {
      font-weight: 600;
    }
  }
  .deleteFromCart {
    display: flex;
    flex-direction: row;
    gap: 10px;
    border: 1px solid #a9a9aa;
    border-radius: 25px;
    margin: auto;
    padding: 3px 13px;
    cursor: pointer;
    svg {
      color: #a9a9aa;
      margin: auto;
    }
    [class~='MuiTypography-root'] {
      color: #a9a9aa;
      font-size: 12px;
      font-weight: 400;
    }
    &:hover {
      background-color: #f1f1f1;
    }
    @media only screen and (max-width: $breakpointMedium) {
      width: 32px;
      height: 32px;
      padding: 0;
      background: #e7e7e7;
    }
  }
  [class~='MuiTableCell-root'] {
    border-top: 1px solid rgba(224, 224, 224, 1);
  }
  @media only screen and (max-width: $breakpointMedium) {
    position: fixed;
    padding: 20px 10px 10px;
    bottom: 56px;
    left: 10px;
    width: calc(100% - 40px);
    [class~='MuiTypography-root'] {
      font-size: 16px;
      color: #1f1f1f;
    }
    .totalAmount {
      [class~='MuiTypography-root'] {
        font-size: 20px;
      }
    }
  }
}

.amountToPayHeight {
  padding: 32px 46px 0;
  height: calc(100vh - 72px);
  @media only screen and (max-width: $breakpointMedium) {
    height: 100%;
    padding: 20px 10px;
  }
}

.toPayDiv {
  height: 180px;
}

.payButton {
  cursor: pointer;
  width: 170px;
  height: 35px;
  border-radius: 28px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  [class~='MuiTypography-root'] {
    color: white;
    font-weight: 400;
    font-size: 16px;
  }
  &:hover {
    filter: brightness(80%);
  }
}

.dateStatus {
  @media only screen and (max-width: $breakpointMedium) {
    color: $simplewhite;
    border-radius: 15px;
    padding: 3px 5px;
    max-height: 30px;
    margin: auto;
    width: 85px;
    [class~='MuiTypography-root'] {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.gridRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .chip {
    width: 24px;
    height: 11px;
    border-radius: $borderRadius;
    margin: 5px;
  }
  [class~='MuiTypography-body1'] {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #1f1f1f;
  }
}

.titlePaymentPopup {
  [class~='MuiTypography-root'] {
    color: #838383;
    font-weight: 400;
    font-size: 20px;
  }
}

.paymentPopupContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  [class~='MuiTypography-root'] {
    font-weight: 400;
    font-size: 16px;
    color: #5f5f5f;
  }
  [class~='MuiAccordion-root'] {
    width: 70%;
    @media only screen and (max-width: $breakpointMedium) {
      width: 100%;
    }
  }
  [class~='MuiFormGroup-root'] {
    align-items: center;
  }
  [class~='MuiRadio-root'] {
    &[class~='Mui-checked'] {
      color: #606060;
    }
  }
  .card {
    border: 1px solid #dddeeb;
    border-radius: 16px;
    padding: 13px 20px;
    max-height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
    justify-content: space-around;
    width: 90%;
    [class~='MuiFormControlLabel-root'] {
      margin-right: 0;
      width: min-content;
    }
    [class~='MuiTypography-root'] {
      font-weight: 400;
      font-size: 14px;
    }
    max-width: 300px;
    .cardRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      white-space: nowrap;
      .cardIcon {
        color: #1f1f1f;
      }
    }
    @media only screen and (max-width: $breakpointMedium) {
      width: 210px;
    }
  }
  .disabledCard {
    border: 1px solid white;
    background: #eaeaea;
  }
  .paymentMethod {
    height: 30px;
    margin: 5px;
    padding: 8px 15px;
    border-radius: $borderRadius;
    border: 1px solid rgba(0, 0, 0, 0.3);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
    width: 100%;

    @media only screen and (max-width: $breakpointMedium) {
      width: 95%;
      padding: 5px;
    }

    cursor: pointer;
    img {
      height: 50%;
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }
  .payButton {
    [class~='MuiTypography-root'] {
      color: white;
    }
  }
  a {
    color: #4653e3;
    font-weight: 700;
  }
}

.modal {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  @media only screen and (max-width: $breakpointMedium) {
    width: 90vw;
    padding: 10px;
    gap: 5px;
    margin: auto;
    margin-top: 70px;
    [class~='MuiTypography-h4'] {
      font-size: 22px;
    }
    [class~='MuiTypography-h6'] {
      font-size: 16px;
    }
  }
}

.collapseCircle {
  min-height: 50px;
  width: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: -25px;
  svg {
    color: white;
  }
}

.kushkiFormContainer {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: $breakpointMedium) {
    width: 100%;
  }
}


.kushkiForm {
  margin-bottom: 20px;
}
